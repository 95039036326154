<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle :title="titleName" :backBtn="false" :downloadBtn="true" @handleDownloadClick="handleDownloadClick">
        </BoxTitle>
        <div class="wztj-module">
            <!-- 物种统计 -->
            <basic-module :text="common.getFyName(fany.wztj)">
                <div slot="basic-module">
                    <div class="wztj-ctn flex-between">
                        <div v-for="(item, index) in resultStaticList" :key="index" class="wztj-item">
                            <div class="name">{{ item.LXNAME }}</div>
                            <div class="value">{{ item.VALUE }}</div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jcsj-module">
            <!-- 多样性统计 -->
            <basic-module :text="common.getFyName(fany.dyxtj)">
                <div slot="basic-module" class="jcsj-bar-chart">
                    <div class="condition flex-end">
                        <div class="line"></div>
                        <SelectTimeTab :selectData="selectData" :tabData="tabData" @getTabItem="getTabItem"
                            @getSelectItem="getSelectItem">
                        </SelectTimeTab>
                    </div>
                    <!-- <NoMonitor
                        v-show="!isChartShow"
                        text="暂无数据"
                    ></NoMonitor> -->
                    <div class="echart-part flex-between">
                        <div class="echart-item" v-for="(item, index) in dyxtjList" :key="index">
                            <div class="title">{{ item.title }}</div>
                            <div class="echart" :id="item.id"></div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jlfx-module">
            <!-- 结论分析 -->
            <basic-module :text="common.getFyName(fany.jlfx)">
                <div slot="basic-module" class="jlfx-border">
                    <div class="jlfx-label">监测结果</div>
                    <div class="jlfx-text">{{ zhpjText | strictNull }}</div>
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
export default {
    name: "",
    components: {
        BoxTitle,
        NoMonitor,
        SelectTimeTab,
    },
    data() {
        return {
            titleName: "动物多样性监测服务",
            zhpjText: "",
            resultStaticList: [],
            allNum: 0,
            dyxtjList: [
                {
                    id: "leftId" + Math.random(),
                    echart: "",
                    title: "分类阶元下占鸟类总记录比例",
                    list: [],
                },
                {
                    id: "rightId" + Math.random(),
                    echart: "",
                    title: "分类阶元下占哺乳类总记录比例",
                    list: [],
                },
            ],
            isChartShow: false,
            tabData: {
                list: [
                    {
                        id: 1,
                        name: "目级分类",
                    },
                    {
                        id: 2,
                        name: "科级分类",
                    },
                ],
                value: "name",
                result: "1",
            },
            selectData: {
                clearable: false,
                state: true,
                width: "100px",
                defaultValue: "",
                list: [
                    {
                        name: "2021",
                    },
                    {
                        name: "2022",
                    },
                    {
                        name: "2023",
                    },
                ],
                label: "name",
                value: "name",
                result: "",
            },
            colorList: [
                "#4a91ff",
                "#65c3ab",
                "#93c284",
                "#f5d65d",
                "#e1844e",
                "#e16f7a",
                "#9f7ae7",
                "#7dc2eb",
                "#f5ad56",
                "#e07de3",
                "#00b0e2",
                "#ebb671",
                "#b78080",
                "#9da450",
                "#7787ad",
                "#cb5c5c",
            ],
        };
    },
    filters: {
        strictNull(value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
        toFixed(value) {
            return Number(value);
        },
    },
    mounted() {
        this.getTimeList();
        this.getSpeciesStatistics();
        this.getJgfx();
    },
    methods: {
        ...mapActions([
            "GetSthjJgtj", // 动物多样性物种统计
            "GetDwdyxTj", // 动物多样性统计
            "GetJlbg", // 获取结论报告
            "GetDwdyxDateYear",
        ]),
        async getYsbczkData() {
            let result = await this.GetDwdyxWztj();
            let res = [
                {
                    PGMC: "物种类型",
                    COUNT: result.WZLX,
                },
                {
                    PGMC: "动物数量",
                    COUNT: result.DWSL,
                },
                {
                    PGMC: "样方数量",
                    COUNT: result.YFSL,
                },
            ];
            let allNum = eval(res.map((item) => item.COUNT).join("+"));
            res.forEach((item) => {
                (item.chartID = "chart" + Math.random()),
                    (item.ratio = ((item.COUNT / allNum) * 100).toFixed(2));
                // item.name = item.PGMC + item.ratio + '%';
                item.name = item.PGMC;
                item.value = item.COUNT;
            });
            this.ysbcztList = res;
            this.allNum = allNum;
        },

        // 获取物种统计
        async getSpeciesStatistics() {
            let res = await this.GetSthjJgtj({
                lx: 3,
            });
            this.resultStaticList = res;
        },
        // tab 切换
        getTabItem(item) {
            this.tabData.result = item.id;
            this.getDwdyxTj();
        },
        // 年份切换
        getSelectItem(item) {
            this.selectData.result = item.name;
            this.selectData.defaultValue = item.name;
            this.getDwdyxTj();
        },
        async getTimeList() {
            let result = await this.GetDwdyxDateYear({});
            this.selectData.list = [];
            result.map((item) => {
                let obj = {
                    name: item,
                };
                this.selectData.list.push(obj);
            });
            if (this.selectData.list.length) {
                this.selectData.result = this.selectData.list[this.selectData.list.length - 1].name;
                this.selectData.defaultValue = this.selectData.list[this.selectData.list.length - 1].name;
                this.getDwdyxTj();
            }
        },
        // 获取多样性统计
        async getDwdyxTj() {
            let result = await this.GetDwdyxTj({
                nf: this.selectData.result,
                lx: this.tabData.result,
            });
            this.dyxtjList[0].list = result.NL;
            this.dyxtjList[1].list = result.BRL;
            this.dyxtjList.map((item) => {
                this.initEchartPic(item.echart, item.id, item.list);
            });
        },
        initEchartPic(echart, id, list) {
            let seriesData = [];
            list.map((item) => {
                let obj = {
                    name: item.TJLX3NAME,
                    value: item.VALUE,
                };
                seriesData.push(obj);
            });
            let options = {
                color: this.colorList,
                tooltip: {
                    trigger: "item",
                },
                legend: {
                    type: "scroll",
                    bottom: "bottom",
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                },
                label: {
                    alignTo: "edge",
                    formatter: function (params) {
                        let str = `${params.value}(${params.percent}%)`;
                        return str;
                    },
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,
                    rich: {
                        time: {
                            fontSize: 10,
                            color: "#999",
                        },
                    },
                },
                labelLine: {
                    length: 15,
                    length2: 0,
                    maxSurfaceAngle: 80,
                },
                labelLayout: function (params) {
                    const isLeft = params.labelRect.x < myChart.getWidth() / 2;
                    const points = params.labelLinePoints;
                    // Update the end point.
                    points[2][0] = isLeft
                        ? params.labelRect.x
                        : params.labelRect.x + params.labelRect.width;
                    return {
                        labelLinePoints: points,
                    };
                },
                series: [
                    {
                        name: "",
                        type: "pie",
                        radius: "50%",
                        data: seriesData,
                    },
                ],
            };

            echart = this.$echarts.dispose(document.getElementById(id));
            echart = this.$echarts.init(document.getElementById(id));
            echart.setOption(options);
        },
        // 结果分析
        async getJgfx() {
            this.zhpjText = "";
            this.bgxzXdlj = "";
            const result = await this.GetJlbg({
                lx: "3",
            });
            if (!result) return;
            this.zhpjText = result.ZHPJ;
            this.bgxzXdlj = result.BGLJ;
        },
        // 报告下载
        handleDownloadClick() {
            if (!this.bgxzXdlj) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open("http://" + this.bgxzXdlj);
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;

    .wztj-module {
        position: relative;
        width: 100%;
        height: 195px;

        .wztj-ctn {
            width: 100%;
            height: 100px;
            background: url("~@image/bigScreen/ecological_environment/topBg.png") no-repeat;
            background-size: 100% 100%;
            padding: 0 150px;

            .wztj-item {
                width: 100px;
                height: 100%;
                position: relative;

                &:nth-child(1) {
                    left: -20px;
                }

                &:nth-child(2) {
                    left: -10px;
                }

                .name {
                    font-size: 14px;
                    color: #aac6fc;
                    position: absolute;
                    left: 50%;
                    bottom: -20px;
                    transform: translateX(-50%);
                }

                .value {
                    font-size: 50px;
                    color: #aac6fc;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-family: "DBPM";
                }
            }
        }
    }

    .jcsj-module {
        width: 100%;
        height: 335px;

        .jcsj-bar-chart {
            position: relative;
            width: 100%;
            height: 100%;

            .condition {
                position: absolute;
                right: 0;
                top: -38px;

                .line {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 522px;
                    height: 1px;
                    background: rgba(53, 80, 124, 0.4);
                    z-index: -1;
                }
            }

            .echart-part {
                width: 100%;
                height: 100%;

                .echart-item {
                    width: 50%;
                    height: 100%;

                    .title {
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        color: #aac6fc;
                    }

                    .echart {
                        width: 100%;
                        height: calc(100% - 20px);
                    }
                }
            }
        }
    }

    .jlfx-module {
        width: 100%;

        .jlfx-border {
            width: 100%;

            .jlfx-label {
                width: 100%;
                color: #5673a2;
            }

            .jlfx-text {
                width: 100%;
                line-height: 24px;
                margin: 5px 0;
                padding: 9px;
                text-align: justify;
                font-size: 16px;
                font-weight: 500;
                color: #bdd7ec;
                background: rgba(26, 48, 89, 0.3);
            }
        }
    }
}
</style>
