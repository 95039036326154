<template>
    <div class="rightPart scrollbar-hidden">
        <BoxTitle :title="titleName" :backBtn="false" :downloadBtn="true" @handleDownloadClick="handleDownloadClick">
        </BoxTitle>
        <!-- 物联网自动虫情测报灯 -->
        <saveState class="ztbcztPart" :stateName="stateName" :stateType="999"></saveState>
        <div class="tjjg-module">
            <!-- 结果统计 -->
            <basic-module :text="common.getFyName(fany.jgtj)">
                <div slot="basic-module">
                    <div class="basicStatistics flex-between-y-center">
                        <div class="centerItem animated bounceInRight" v-for="(item, index) in resultStaticList"
                            :key="index" :style="{
                                animationDelay: 0.2 + index * 0.2 + 's',
                                width: item.width ? item.width : '150px',
                            }">
                            <div class="name">
                                {{ item.LXNAME }}
                            </div>

                            <div class="count flex-x-center flex-y-center">
                                <span v-show="item.VALUE" class="num">{{
                                    item.VALUE
                                }}</span>
                                <span v-show="!item.VALUE" class="zwsj">暂无数据</span>
                            </div>
                        </div>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jcsj-module">
            <!-- 监测数据 -->
            <basic-module :text="common.getFyName(fany.jcsj)">
                <div slot="basic-module" class="jcsj-bar-chart">
                    <div class="condition flex-end">
                        <div class="line"></div>
                        <SelectTimeTab :selectData="selectData" :tabData="tabData" @getTabItem="getTabItem"
                            @getSelectItem="getSelectItem">
                        </SelectTimeTab>
                    </div>
                    <!-- <NoMonitor
                        v-show="!isChartShow"
                        text="暂无数据"
                    ></NoMonitor> -->
                    <div class="echart-line flex-between" v-if="tabData.result == '目级分类'">
                        <div :style="{ width: echartLinePart.leftData.length > echartLinePart.rightData.length ? '440px' : '200px' }"
                            class="echartLine1" :id="echartLinePart.leftId"></div>
                        <div :style="{ width: echartLinePart.leftData.length > echartLinePart.rightData.length ? '200px' : '440px' }"
                            class="echartLine2" :id="echartLinePart.rightId"></div>
                    </div>
                    <div class="echart-pic" v-else>
                        <rosePieChart v-show="kjflPart.total" :chartData="kjflPart.list"
                            :lengendData="kjflPart.legendList" @getEchartItemName="getEchartItemName"></rosePieChart>
                        <NoMonitor v-show="!kjflPart.total" text="暂无数据"></NoMonitor>
                    </div>
                </div>
            </basic-module>
        </div>
        <div class="jlfx-module">
            <!-- 结论分析 -->
            <basic-module :text="common.getFyName(fany.jlfx)">
                <div slot="basic-module" class="jlfx-border">
                    <div class="jlfx-label">绿色防治</div>
                    <div class="jlfx-text">
                        <span v-html="zhpjText"></span>
                        <!-- {{ zhpjText | strictNull }} -->
                    </div>
                    <!-- <div class="jlfx-label">古茶林保护建议</div>
                    <div class="jlfx-text">{{ bhjyText | strictNull }}</div> -->
                </div>
            </basic-module>
        </div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import BoxTitle from "@views/big_screen/comp/BoxTitle.vue"; //标题
import NoMonitor from "@views/big_screen/comp/NoMonitor.vue"; //标题
import saveState from "../comp/saveState";
import rosePieChart from "../comp/rose_pie_chart.vue";
import SelectTimeTab from "@views/big_screen/comp/SelectTimeTab";
export default {
    name: "",
    components: {
        BoxTitle,
        NoMonitor,
        saveState,
        rosePieChart,
        SelectTimeTab,
    },
    data() {
        return {
            titleName: "病虫害-虫情测报服务",
            // 总体保存状况
            stateName: "物联网自动虫情测报灯",
            jcsbList: [
                {
                    id: "1",
                    label: "芒景-芒洪古茶林虫情监测站",
                },
                {
                    id: "2",
                    label: "大平掌古茶林虫情监测站",
                },
            ],
            currentJcsbId: "1",
            resultStaticList: [], //结果统计列表
            zhpjText: "",
            bhjyText: "",
            bgxzXdlj: "", //报告下载
            tabData: {
                list: [
                    {
                        name: "目级分类",
                    },
                    // {
                    //     name: "科级分类",
                    // },
                ],
                value: "name",
                result: "目级分类",
            },
            selectData: {
                clearable: false,
                state: true,
                width: "100px",
                defaultValue: "",
                // {
                //         name: "2021",
                //     },
                list: [],
                label: "name",
                value: "name",
                result: "",
            },
            echartLinePart: {
                leftId: "leftId" + Math.random(),
                leftEchart: "",
                rightId: "rightId" + Math.random(),
                rightEchart: "",
                leftData: [],
                rightData: [],
            },
            // 科级分类
            kjflPart: {
                list: [],
                legendList: [],
                total: 0,
            },
        };
    },
    filters: {
        strictNull(value) {
            if (value === null || value === undefined || value === "") {
                return "暂无数据";
            }
            return value;
        },
        toFixed(value) {
            return Number(value);
        },
    },
    mounted() {
        this.getTimeList();
        this.getResultStatistics();
        this.getAnniversary();
    },
    methods: {
        ...mapActions([
            "GetSthjJgtj",
            "GetResultStatis",
            "GetJlbg",
            "GetGsmmKstj",
            "GetCqcbDateYear"
        ]),
        async getTimeList() {
            let list = await this.GetCqcbDateYear({});
            this.selectData.list = [];
            list.map((item) => {
                let obj = {
                    name: item,
                };
                this.selectData.list.push(obj);
            });
            if (this.selectData.list.length) {
                this.selectData.result = this.selectData.list[this.selectData.list.length-1].name;
                this.selectData.defaultValue = this.selectData.list[this.selectData.list.length-1].name;
                this.getMonitorData();
            }
        },
        // tab 切换
        getTabItem(item) {
            this.tabData.result = item.name;
            if (item.name == "目级分类") {
                this.selectData.state = true;
                this.getMonitorData();
            } else {
                this.selectData.state = false;
                this.getGsmmKstj();
            }
        },
        // 年份切换
        getSelectItem(item) {
            this.selectData.result = item.name;
            this.selectData.defaultValue = item.name;;
            this.getMonitorData();
        },
        // 获取监测数据
        async getMonitorData() {
            let result = await this.GetResultStatis({
                nf: this.selectData.result,
            });
            this.echartLinePart.leftData = result.DSJ
            this.echartLinePart.rightData = result.XSJ
            if (this.echartLinePart.leftEchart) {
                this.echartLinePart.leftEchart = this.$echarts.dispose(document.getElementById(this.echartLinePart.leftId));
            }
            if (this.echartLinePart.rightEchart) {
                this.echartLinePart.rightEchart = this.$echarts.dispose(document.getElementById(this.echartLinePart.rightId));
            }
            this.$nextTick(() => {
                this.initEchartLine(
                    this.echartLinePart.leftEchart,
                    this.echartLinePart.leftId,
                    "#3577b2",
                    result.DSJ
                );
                this.initEchartLine(
                    this.echartLinePart.rightEchart,
                    this.echartLinePart.rightId,
                    "#ed7d31",
                    result.XSJ
                );
            })

        },
        initEchartLine(echart, id, color, list) {
            let xAxisData = [];
            let seriesData = [];
            list.map((item) => {
                xAxisData.push(item.TJLX2NAME);
                seriesData.push(item.VALUE);
            });
            let options = {
                color: color,
                tooltip: {
                    trigger: "axis",
                    padding: [2, 10],
                    textStyle: {
                        fontSize: 16,
                        align: "left",
                    },
                    axisPointer: {
                        type: "none",
                        lineStyle: {
                            color: {
                                type: "linear",
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [
                                    {
                                        offset: 0,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                    {
                                        offset: 0.5,
                                        color: "rgba(255, 255, 255,1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(0, 255, 233,0)",
                                    },
                                ],
                                global: false,
                            },
                        },
                    },
                },
                legend: {
                    icon: "rect",
                    itemWidth: 14,
                    itemHeight: 5,
                    itemGap: 13,
                    textStyle: {
                        color: "rgba(196,214,225,1)",
                    },
                    left: "left",
                    top: "5%",
                },
                grid: {
                    top: 40,
                    bottom: 40,
                    left: 50,
                    right: 40,
                },
                xAxis: [
                    {
                        name: "",
                        nameTextStyle: {
                            fontSize: 12,
                            color: "#9AB4E3",
                        },
                        boundaryGap: true, // 默认，坐标轴留白策略
                        type: "category",
                        axisLabel: {
                            interval: 0,
                            rotate: 45,
                            textStyle: {
                                color: "#A8C8FF",
                            },
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: "#0f5378",
                            },
                        },
                        splitArea: {
                            color: "#f00",
                            lineStyle: {
                                color: "#e2e9ff",
                            },
                        },
                        data: xAxisData,
                    },
                ],
                yAxis: {
                    name: "个",
                    nameTextStyle: {
                        fontSize: 12,
                        color: "#9AB4E3",
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                        },
                    },
                    axisLabel: {
                        margin: 20,
                        fontSize: 12,
                        textStyle: {
                            color: "#9AB4E3",
                            // color: "rgba(53,80,124,1)",
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(53,80,124,0.2)",
                            width: 2,
                        },
                    },
                },
                series: [
                    {
                        name: ``,
                        type: "bar",
                        barWidth: "20",
                        data: seriesData,
                    },
                ],
            };
            echart = this.$echarts.dispose(document.getElementById(id));
            echart = this.$echarts.init(document.getElementById(id));
            echart.setOption(options);
        },
        // 获取科级分类
        async getGsmmKstj() {
            this.kjflPart.total = 0;
            this.kjflPart.legendList = [];
            const result = await this.GetGsmmKstj();
            if (result && result.length > 0) {
                this.kjflPart.list = result;
                this.kjflPart.list.map((item) => {
                    item.name = item.NAME;
                    item.value = item.SL;
                    this.kjflPart.total += Number(item.SL);
                    this.kjflPart.legendList.push(item.NAME);
                });
            }
        },
        // 获取结论分析
        async getAnniversary() {
            this.zhpjText = "";
            this.bhjyText = "";
            this.bgxzXdlj = "";
            const result = await this.GetJlbg({
                lx: "5",
            });
            if (!result) return;
            this.zhpjText = result.ZHPJ;
            this.bhjyText = result.BHJY;
            this.bgxzXdlj = result.BGLJ;
        },
        // 报告下载
        handleDownloadClick() {
            if (!this.bgxzXdlj) {
                this.common.showMsg("暂无报告", "warning");
                return;
            }
            window.open("http://" + this.bgxzXdlj);
        },
        // 获取结果统计
        async getResultStatistics() {
            let res = await this.GetSthjJgtj({
                lx: 5,
            });
            this.resultStaticList = res;
        },
    },
};
</script>
<style scoped lang="scss">
.rightPart {
    width: 100%;
    height: 100%;
    padding: 15px 0;

    .ztbcztPart {
        height: 92px;
        padding: 0 24px;
        margin-top: 15px;
    }

    .tjjg-module {
        width: 100%;

        .basicStatistics {
            width: 100%;
            height: 100%;
            position: relative;

            .centerItem {
                width: 162px;

                .name {
                    width: 100%;
                    height: 40px;
                    background: url("~@image/bigScreen/icon/titleBg.png") no-repeat;
                    background-size: 100% 100%;
                    font-size: 18px;
                    font-weight: 500;
                    color: rgba(168, 200, 255, 1);
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                }

                .count {
                    margin-top: 5px;
                    height: 65px;
                    border: 1px solid rgba(53, 80, 124, 0.4);
                    color: #bdd7ec;
                    position: relative;

                    .num {
                        font-size: 48px;
                        font-family: DBPM;
                    }

                    .zwsj {
                        font-size: 20px;
                    }

                    .dw {
                        margin-left: 5px;
                        font-size: 20px;
                        color: #a8c8ff;
                    }
                }
            }
        }
    }

    .jcsj-module {
        width: 100%;
        height: 300px;

        .jcsj-bar-chart {
            position: relative;
            width: 100%;
            height: 100%;

            .condition {
                position: absolute;
                right: 0;
                top: -38px;

                .line {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 522px;
                    height: 1px;
                    background: rgba(53, 80, 124, 0.4);
                    z-index: -1;
                }
            }

            .echart-line {
                width: 100%;
                height: 100%;

                .echartLine1 {
                    width: 200px;
                    height: 100%;
                }

                .echartLine2 {
                    width: 440px;
                    height: 100%;
                }
            }

            .echart-pic {
                width: 100%;
                height: 100%;
            }
        }
    }

    .jlfx-module {
        width: 100%;

        .jlfx-border {
            width: 100%;

            .jlfx-label {
                width: 100%;
                color: #5673a2;
            }

            .jlfx-text {
                width: 100%;
                line-height: 24px;
                margin: 5px 0;
                padding: 9px;
                text-align: justify;
                font-size: 16px;
                font-weight: 500;
                color: #bdd7ec;
                background: rgba(26, 48, 89, 0.3);
            }
        }
    }
}
</style>
